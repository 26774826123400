import * as React from "react"
import { Helmet } from "react-helmet"

function NewsStructuredData({ description, pageurl, title, image, author, date }) {
    var ldJson = {
        "@context": "http://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": pageurl
        },
        "headline": title,
        "image": [
            image
        ],
        "datePublished": date,
        "dateModified": date,
        "author": {
            "@type": "Person",
            "name": `${author ? author : "Allegiance Real Estate Dubai"}`
        },
        "publisher": {
            "@type": "Organization",
            "name": "Allegiance Real Estate Dubai",
            "logo": {
                "@type": "ImageObject",
                "url": "https://ggfx-allegiance.s3.eu-west-2.amazonaws.com/i.prod/brandlogo_1899d71195.png"
            }
        },
        "description": description
    }
    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(ldJson, null, 2)}
            </script>
        </Helmet>
    )
}
export default NewsStructuredData