import React, { useRef } from "react"
import TermsPolicySection from "../TermsPolicySection/TermsPolicySection"
import { postFormData } from "./api/Api"
import Form from "./elements/Form"
import TextInput from "./elements/TextInput"
import { newsLetterSchema } from "./schemas"
import "./Form.scss"
import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Recaptcha from "./elements/Recaptcha"
import { formTracking, SendMail } from "../common/site/utils"

const NewsletterForm = () => {
  const recaptchaRef = useRef()

  const onSubmit = async data => {
    const token = await recaptchaRef.current.executeAsync()
    if (token) {
      let postData = { ...data, ...FORM_CONFIG.newsletter }
      recaptchaRef.current.reset()
      postFormData(postData).then(async apiRes => {
        await SendMail(postData)
      })
      formTracking({
        event_tracking: FORM_CONFIG.newsletter.event_tracking,
        form_name: FORM_CONFIG.newsletter.form_name,
      })
    }
  }
  return (
    <>
      <Form
        className="form-enquiry base-form"
        validationSchema={newsLetterSchema}
        onSubmit={onSubmit}
        successMsg={SUCCESS_MSG}
      >
        <TextInput name="name" placeholder="Name" />
        <TextInput name="email" placeholder="Email" />
        <button className="button button-black" type="submit">
          Subscribe
        </button>
      </Form>
      <TermsPolicySection isNewsletter/>
      <div className="d-none">
        <Recaptcha recaptchaRef={recaptchaRef} />
      </div>
    </>
  )
}

export default NewsletterForm
