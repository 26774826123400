import { Link, useStaticQuery,graphql } from "gatsby"
import React, { useState } from "react"
import parse from "html-react-parser"
import "./StaticContactCard.scss"
import GuideForm from "../forms/GuideForm"
import { Modal } from "react-bootstrap"
import DetailStaticForm from "../forms/DetailStaticForm"
import SideBarNewsLetter from "../SideBarNewsLetter/SideBarNewsLetter"
import { teamsURL } from "../../site/urls"

const StaticContactCard = props => {
  const data = useStaticQuery(graphql`
    query getPdfUrl {
      glstrapi {
        globalModule {
          Email_pdf {
            url
          }
        }
      }
    }
  `)


  const [showModal, setShowModal] = useState(false)
  const { content, team, isBlogDetail } = props
  const pageName = props.GQLPage?.menu?.label
  const modalHandler = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
  }


  const telNumber=team?.mobile_no?team.mobile_no:"123456789"

  const guidePdfUrl=data?.glstrapi?.globalModule?.Email_pdf?.url

  return (
    <div className="static-contact-card-wrap">
       {!isBlogDetail && (
      <div className="static-border">
      {content && (
        <div className="content-section">
          <p className="title">{content.title}</p>
          {(content.description || content.desc) && (
            <div className="description">
              {parse(content.description ? content.description : content.desc)}
            </div>
          )}
          <div className="cta-section">
             <a href={"tel:" + telNumber} className="button button-black">
              <i className="icon call-icon"></i>
              <span>call</span>
            </a>
            <a
              to="#"
              className="button button-black"
              onClick={() => modalHandler()}
            >
              <i className="icon envelope-icon"></i>
              <span>email</span>
            </a>
          </div>
        </div>
      )}
      {team && (
        <div className="team-section">
            <img
              src={team.image?.url ? team.image.url : team.tile_image?.url}
              alt={team.name}
              className="team-img"
            />
          <div className="team-data-section">
            <p className="name">
              {team.name}
            </p>
            <p className="designation">{team.designation}</p>
            <p className="languages">Speaks {team.languages}</p>
          </div>
        </div>
      )}
      </div>
      )}
      {isBlogDetail && (
          <SideBarNewsLetter />
      )}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="more-filters-modal stay-updated team-listing-modal"
        id="filter-modal"
        // fullscreen={"xl-down"}
      >
        <button
          className="stay-button-form team-listing-form"
          type="button"
          onClick={() => closeModal()}
        >
          <i className="icon cancel-icon" />
        </button>
        <Modal.Body>
          <div className="modal-body-container">
            {props.pageLayout === "static_template" ? (
              <GuideForm pageName={pageName} guidePdfUrl={guidePdfUrl}/>
            ) : (
              <DetailStaticForm />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default StaticContactCard
