import React, { useState } from "react"
import { Container } from "react-bootstrap"
import "./BlogDetailComponent.scss"
import { format } from "date-fns/esm"
import SocialShareComponent from "../SocialShareComponent/SocialShareComponent"
import parse from "html-react-parser"
import StaticContactCard from "../StaticContactCard/StaticContactCard"

const BlogDetailComponent = ({ blogDetails }) => {
  const [shareIcons, setShareIcons] = useState(false)

  const { title, tile_image, components, post_on, blog_category, team, Ghost_Page } =
    blogDetails
  const formattedDate = format(new Date(post_on), "d MMM y")
  const openShareicons = () => {
    setShareIcons(!shareIcons)
  }
  const shareurl = typeof window !== "undefined" ? window.location.href : ""
  const capitalize = str => {
    if (str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  }

  const staticData = {
    title: "Do you need help with moving house in Dubai?",
    desc: "Relocating in Dubai? We can help with your move! Contact Us Now",
  }

  return (
    <>
      <div className="blog-detail-wrapper">
        <Container>
          <div className="blog-details-section">
            <h1>{title}</h1>
            <div className="blog-info-sec">
              <div className="date">{formattedDate}</div>
              <div className="vertical-line"></div>
              {blog_category && <div className="blog-type">{capitalize(blog_category.name)}</div>}
              {!Ghost_Page && <div className="vertical-line"></div> }
              {!Ghost_Page && <div
                className="share-blog-detail"
                onClick={() => openShareicons()}
              >
                <i className="icon black-share-icon" />
                <span className="blog_detail">Share</span>
                {shareIcons && (
                  <SocialShareComponent
                    openShareicons={openShareicons}
                    shareurl={shareurl}
                  />
                )}
              </div>}
            </div>

            <div className="blog-side-section">
              <div className="blog-content-wrap">
                {components.map(data => (
                  <div>
                    {data.image && (
                      <div className="blog-content-img">
                        <img src={data.image.url} alt="blog-image" />
                      </div>
                    )}
                    {data.text && (
                      <div className="blog-details-text">
                        {parse(data.text)}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <div className="blog-contact-details">
                <StaticContactCard content={staticData} team={team} isBlogDetail />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  )
}

export default BlogDetailComponent
