import { format } from "date-fns/esm"
import { Link } from "gatsby"
import React from "react"
import { newsURL } from "../../site/urls"
import GetGGFXImage from "../common/site/GetGGFXImage"
import "./NewsCard.scss"
import {
  inViewOptions2,
  containerVariants,
  fadeInFromTop,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const NewsCard = ({ blog }) => {
  const formattedDate = format(new Date(blog.post_on), "d MMMM y")

  const ImageRender = ({ blog }) => {
    let processedImages = blog.imagetransforms?.tile_image_Transforms
    processedImages ||= JSON.stringify({})
    return (
      <GetGGFXImage
        imagename={"blog.tile_image.tileimg"}
        imagesource={blog.tile_image}
        fallbackalt={blog.title}
        imagetransformresult={processedImages}
        id={blog.id}
        className="news-img"
      />
    )
  }

  return (
    <InView {...inViewOptions2}>
      {({ ref, inView }) => (
        <motion.div
          className="animation-component"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
        >
          <motion.div className="news-card" variants={fadeInFromTop}>
            <div className="image-section">
              <Link to={`${newsURL}${blog.slug}/`}>
                {/* <img src={blog.tile_image.url} alt="" className="news-img" /> */}
                <ImageRender blog={blog} />
                {blog.blog_category && <div className="image-tag">
                  <p className="tag">{blog.blog_category.name}</p>
                </div> }
              </Link>
            </div>
            <div className="content">
              <Link to={`${newsURL}${blog.slug}/`}>
                <p className="title">{blog.title}</p>
              </Link>
              <p className="date">{formattedDate}</p>
            </div>
          </motion.div>
        </motion.div>
      )}
    </InView>
  )
}

export default NewsCard
