import { graphql } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import BlogDetailComponent from "../components/BlogDetailComponent/BlogDetailComponent"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import NewsSlider from "../components/NewsSlider/NewsSlider"
import { aboutURL, newsURL } from "../site/urls"
import SEO from "../components/Seo/seo"
import useCompanyInfo from "../hooks/useCompanyInfo"
import useDeviceMedia from "../hooks/useDeviceMedia"
import { getWhatsAppURL } from "../components/common/site/utils"
import balckPhoneImg from "../images/black-phone.svg"
import whatsappImg from "../images/whatsapp.svg"
import MobileContact from "../components/MobileContact/MobileContact"
import NewsStructuredData from "../components/Seo/NewsStructuredData"
import { format } from "date-fns/esm"

const BlogDetailsTemplate = ({ data }) => {
  const { phone } = useCompanyInfo()
  const { isLargeScreen, isMobile } = useDeviceMedia()
  const blogDetails = data.glstrapi.blog
  const module = {
    title: "latest news",
    description:
      "Stay updated with the most recent articles and blogs from Dubai's top real estate professionals.",
  }
  const staticURLs = [
    { url: aboutURL, label: "About" },
    { url: newsURL, label: "News" },
    { url: "", label: blogDetails.title },
  ]
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  const formattedDate = format(new Date(blogDetails.post_on), "d MMMM y")
  const seoDescription = `Read about ${blogDetails?.title?.toLowerCase()} here and subscribe to our newsletter to stay up-to-date about everything going on at Allegiance Real Estate.`
  return (
    <>
      {blogDetails.Ghost_Page && <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>}
      <NewsStructuredData
        image={blogDetails?.tile_image?.url}
        pageurl={pageurl}
        title={blogDetails.title}
        author={blogDetails?.author?.name}
        description={seoDescription}
        date={formattedDate}
      />
      <div className="blog-details-page">
        <SEO
          title={blogDetails.title}
          description={
            seoDescription
          }
        />
        <Header />
        {!isLargeScreen &&
          <div className="social-section">
            <a href={getWhatsAppURL(phone)} target="_blank">
              <img
                src={whatsappImg}
                alt="social-whatsapp"
                className="social-icon social-whatsapp"
              />
            </a>
            <a href={"tel:" + phone}>
              <img
                src={balckPhoneImg}
                alt="social-phone"
                className="social-icon social-phone"
              />
            </a>
          </div>
        }
        {
          isMobile &&
          <MobileContact />
        }
        <Breadcrumbs staticURLs={staticURLs} />
        <BlogDetailComponent blogDetails={blogDetails} />
        {!blogDetails.Ghost_Page && <div className="blog-latest-news">
          <NewsSlider module={module} newsId={blogDetails?.id} />
        </div>}
        <Footer categoryType="category1" />
      </div>
    </>
  )
}

export default BlogDetailsTemplate

export const pageQuery = graphql`
  query getBlogPage($articleId: ID!) {
    glstrapi {
      blog(id: $articleId) {
        id
        Ghost_Page
        title
        tile_image {
          url
        }
        post_on
        blog_category {
          name
        }
        team {
          ...teamData
        }
        components {
          ... on GLSTRAPI_ComponentBlocksRichText {
            id
            text
          }
          ... on GLSTRAPI_ComponentBlocksImage {
            id
            image {
              url
            }
          }
        }
      }
    }
  }
`
