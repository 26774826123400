import { Link } from 'gatsby'
import React from 'react'
import useDeviceMedia from '../../hooks/useDeviceMedia'

const MobileContact = () => {
    const {isMobile}=useDeviceMedia()
  return (
    <div className="mobile-floating-buttons">
        <a
            className="button button-black"
            href={"tel:" + "+971 5 641 44147"}
        >
            <i className="icon call-icon" />{" "}
            <span>{isMobile ? "" : "call"}</span>
        </a>
        <Link to="/contact-allegiance" className="button button-black">
        <i className="icon envelope-icon" />{" "}
        <span>{isMobile ? "" : "email"}</span>
        </Link>
    </div>
  )
}

export default MobileContact