import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import NewsCard from "../NewsCard/NewsCard"
import SliderModule from "../SliderModule/SliderModule"
import "./NewsSlider.scss"
import {
  inViewOptions2,
  containerVariants,
} from "../utils/animation"

import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"

const NewsSlider = ({ module, newsId }) => {
  const data = useStaticQuery(graphql`
    query getNewsSliderData {
      glstrapi {
        blogs (sort:"post_on:desc", where: {Ghost_Page_ne:true}, limit:6) {
          ...blogCardDetails
        }
      }
    }
  `)

  const blogs = data.glstrapi.blogs

  const sliderData = blogs.filter(x => x.id !== newsId).map(blog => <NewsCard blog={blog} />)

  return (
    <InView {...inViewOptions2}>
    {({ ref, inView }) => (
      <motion.div
        className="animation-component"
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={containerVariants}
      >
    <div className="areaguide-slider-module">
      <SliderModule
        title={module.title}
        description={module.description}
        sliderData={sliderData}
        className="areaguide-slider-wrap"
        sliderClassName="areaguide-slider-section"
        theme={module.theme}
        size="md"
      />
    </div>
    </motion.div>
    )}
    </InView>
  )
}

export default NewsSlider
