import clsx from "clsx"
import React from "react"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./SliderModule.scss"
import parse from "html-react-parser"
import { Container } from "react-bootstrap"
import {
  fadeInFromLeft,

} from "../utils/animation"
import { motion } from "framer-motion"

const SliderModule = ({
  title,
  description,
  sliderData,
  className,
  theme,
  sliderClassName,
  size,
  noContainer,
}) => {
  const ContainerSelect = ({ noContainer, children, className }) =>
    noContainer ? (
      <div className={className}>{children}</div>
    ) : (
      <Container className={className}>{children}</Container>
    )

  return (
    
    <div 
   
      className={clsx(
        "slider-module-wrap",
        className,
        theme === "white" ? "section-m" : "section-p",
        {
          "grey-bg": theme === "grey",
          "black-bg": theme === "black",
        }
      )}
    >
      <ContainerSelect
        noContainer={noContainer}
        className={clsx("slider-module-container")}
      >
        <motion.div variants={fadeInFromLeft} className="slider-content">
          <h2 className="slider-title">{title}</h2>
          {description && (
            <div className="slider-description">{parse(description)}</div>
          )}
        </motion.div>
        <CustomSlider
          size={size}
          length={sliderData?.length}
          className={clsx("slider-module-slider", sliderClassName)}
        >
          {sliderData}
        </CustomSlider>
      </ContainerSelect>
    </div>
   
  )
}

export default SliderModule
