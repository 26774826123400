import React from "react"
import NewsletterForm from "../forms/NewsletterForm"
import "./SideBarNewsLetter.scss"

const SideBarNewsLetter = () => {
  const title = `Keep yourself informed by signing up for our newsletter.`
  const description = ``
  return (
    <div className="sidebar-newsletter-card">
      <div>
        <h3>{title}</h3>
        <p className="desc">{description}</p>
      </div>
      <NewsletterForm isNewsLetter />
    </div>
  )
}

export default SideBarNewsLetter
