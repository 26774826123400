import React, { useRef } from "react"
import TermsPolicySection from "../TermsPolicySection/TermsPolicySection"
import { postFormData } from "./api/Api"
import Form from "./elements/Form"
import TextInput from "./elements/TextInput"
import "react-datepicker/dist/react-datepicker.css"
import "./Form.scss"
import FORM_CONFIG, { SUCCESS_MSG } from "./config"
import Recaptcha from "./elements/Recaptcha"
import { formTracking, SendMail } from "../common/site/utils"
import { guideSchema } from "./schemas"

const DetailStaticForm = () => {
  const recaptchaRef = useRef()

  const onSubmit = async data => {
    const token = await recaptchaRef.current.executeAsync()
    if (token) {
      let postData = { ...data, ...FORM_CONFIG.detail_Static }
      recaptchaRef.current.reset()
      postFormData(postData).then(async apiRes => {
        await SendMail(postData)
      })
      formTracking({
        event_tracking: FORM_CONFIG.detail_Static.event_tracking,
        form_name: FORM_CONFIG.detail_Static.form_name,
      })
    }
  }
  return (
    <>
      <div className="stay-updated-content individual-team-content">
        <h4>Contact Us</h4>
      </div>
      <Form
        className="custom-modal-form individual-team-form"
        validationSchema={guideSchema}
        onSubmit={onSubmit}
        successMsg={SUCCESS_MSG}
      >
        <div className="form-row">
          <TextInput
            name="firstname"
            placeholder="First Name"
            position="left"
          />
          <TextInput name="lastname" placeholder="Last Name" />
        </div>

        <div className="form-row">
          <TextInput name="email" placeholder="Email" position="left" />
          <TextInput
            name="telephone"
            placeholder="Mobile Number"
            type="number"
          />
        </div>
        <button className="button button-black" type="submit">
          Submit
        </button>
      </Form>
      <TermsPolicySection />
      <div className="d-none">
        <Recaptcha recaptchaRef={recaptchaRef} />
      </div>
    </>
  )
}

export default DetailStaticForm
